<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <HeadBalance
              :currency="currency"
              :activeCurrencyObj="ccurrencyObj"
            />

            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <a class="nav-link deposit-head active text-white" href="#">
                  Deposit
                </a>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link withdraw-head text-white"
                  :to="`/settings/wallets/idr_withdraw`"
                >
                  Withdraw
                </router-link>
              </li>
            </ul>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">&nbsp;</h5>
                <div class="row">
                  <div class="col">
                    <div class="alert alert-warning">
                      <strong>Syarat dan Ketentuan</strong>
                      <br /><br />
                      <ol>
                        <li>
                          Harap perhatikan instruksi berikut ini sehingga
                          deposit yang dilakukan dapat segera diproses
                        </li>
                        <li>
                          Transfer bank "WAJIB" menggunakan akun dengan nama
                          resmi anda
                        </li>
                        <li>Setor dana minimal Rp 100,000</li>
                      </ol>
                      <br />
                      <br />
                      <span
                        >CATATAN: kegagalan dalam mengikuti instruksi diatas
                        dapat mengakibatkan deposit tertunda hingga 1 minggu
                        atau sampai waktu yang tidak dapat ditentukan.</span
                      >
                    </div>

                    <form @submit.prevent="submitFormDeposit">
                      <div class="form-row mt-4">
                        <div class="col-md-12">
                          <label>Jumlah Deposit:</label>
                          <input
                            type="number"
                            v-model="formData.amount"
                            class="form-control"
                          />
                        </div>

                        <div class="col-md-12 mt-3">
                          <label>Deskripsi:</label>
                          <textarea
                            type="text"
                            v-model="formData.description"
                            class="form-control"
                          />
                        </div>

                        <div class="col-md-12 mt-3">
                          <button type="submit" class="btn btn-gradient">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Daftar Deposit</h5>

                <div class="card bg-dark mb-3">
                  <div class="card-body">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <p class="text-light mb-1">Total Deposit Berhasil</p>
                        <h5 class="mb-0 text-success">
                          {{ totalAmountDeposits.numberFormat(2) }} IDR
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="wallet-history">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-left">No.</th>
                        <th class="text-left">Date</th>
                        <th class="text-left">Status</th>
                        <th class="text-right">Jumlah</th>
                        <th class="text-left">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in deposits" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                          {{
                            moment(item.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          }}
                        </td>
                        <td>
                          <span
                            class="text-warning"
                            v-if="item.depoidr_status === 'PENDING'"
                          >
                            PENDING
                          </span>
                          <span
                            class="text-success"
                            v-if="item.depoidr_status === 'PAID'"
                          >
                            PAID
                          </span>
                          <span
                            class="text-danger"
                            v-if="item.depoidr_status === 'EXPIRED'"
                          >
                            EXPIRED</span
                          >
                        </td>
                        <td class="text-right">
                          {{ parseFloat(item.depoidr_amount).numberFormat(2) }}
                        </td>
                        <td>
                          <div v-if="item.depoidr_status == 'PENDING'">
                            <a
                              :href="item.xendit_obj?.data?.invoice_url"
                              target="_blank"
                              class="btn btn-sm btn-gradient"
                              >Bayar sekarang</a
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Pagination
                  v-if="deposits.length > 0 || pagination.currentPage > 1"
                  :current-page="pagination.currentPage"
                  :last-page="pagination.lastPage"
                  @page-change="handlePageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";
import Pagination from "@/components/global/Pagination.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    HeadBalance,
    Pagination,
  },
  data() {
    return {
      themain,
      moment,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      currency: "IDR",
      ccurrencyObj: {
        ccurrency_config: {
          address_view: "",
          deposit_info_view: "",
          deposit_manual_view: "",
        },
      },
      formData: {
        amount: 0,
        description: "",
        payer_email: "",
      },
      minAmountIdr: 0,

      deposits: [],
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        lastPage: true,
      },

      totalAmountDeposits: 0,

      user: {},

      deposit: {
        depoidr_amount: 0,
      },
    };
  },

  sockets: {
    connect: function () {
      // PERHATIAN: JANGAN DI COMMENT LOG INI KARENA DI PRODUCTION AKAN MENYEBABKAN ERROR
      console.log("socket connected");
    },
    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        if (parseInt(self.usid) == el.u) {
          self.getDeposits();
          self.getTotalAmountDeposits();
        }
      });
    },
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;
    await self.getCcurrencyData();
    await self.getUserData();

    // ambil data deposit
    await self.getDeposits();
    await self.getMinAmountIDR();
    await self.getTotalAmountDeposits();
  },

  methods: {
    async getUserData() {
      var self = this;

      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;
        self.formData.payer_email = res.orang_email;
      } else {
        self.$router.push("/");
      }
    },

    async getCcurrencyData() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getCurrencies(1, 1, "id", "desc", filter_rules);
      self.ccurrencyObj = res.datas[0];
    },

    async getDeposits() {
      var self = this;
      var filter_rules = [];
      var depoRes = await thestore.getWalletsIdrDeposits(
        self.token,
        self.pagination.currentPage,
        self.pagination.itemsPerPage,
        "id",
        "desc",
        filter_rules
      );
      self.deposits = depoRes.datas;

      if (self.deposits.length < self.pagination.itemsPerPage) {
        self.pagination.lastPage = true;
      } else {
        self.pagination.lastPage = false;
      }
    },

    async getTotalAmountDeposits() {
      var self = this;
      var filter_rules = [
        { field: "depoidr_status", op: "equal", value: "PAID" },
      ];
      var depoRes = await thestore.getWalletsIdrDeposits(
        self.token,
        1,
        "",
        "",
        "desc",
        filter_rules
      );

      self.totalAmountDeposits = depoRes.datas.reduce(
        (acc, cur) => acc + parseFloat(cur.depoidr_amount),
        0
      );
    },

    navigateToInvoiceUrl(url) {
      window.open(url, "_blank");
    },

    async submitFormDeposit() {
      var self = this;

      const userId = Cookies.get("usid");

      if (self.formData.description === "") {
        self.formData.description = "deposit";
      }

      const datas = { ...self.formData, user_id: userId };

      try {
        const res = await thestore.postDepositIDR(datas, self.token);

        if (res.success) {
          self.navigateToInvoiceUrl(res.data.xendit_response.invoice_url);
          self.getDeposits();
        } else {
          toast.warning(res.msg);
        }
      } catch (error) {
        if (error.response) {
          // Respons error dari server
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
            console.error("Error 400:", error.response.data.message);
          } else {
            // toast.error(error.response.data);
            console.error("Error:", error.response.data);
          }
        } else if (error.request) {
          // Request dikirim tetapi tidak ada respons
          toast.error(error.request);
          console.error("No response received:", error.request);
        } else {
          // Terjadi kesalahan saat menyiapkan request
          toast.error(error.message);
          console.error("Error setting up request:", error.message);
        }
      }
    },

    async getMinAmountIDR() {
      const res = await thestore.getMinDepositAndWDAmount();
      this.minAmountIdr = res.minimal_amount_deposit;
    },

    formatRupiah(value) {
      if (!value) return "Rp 0";
      return "Rp " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    handlePageChange(page) {
      this.pagination.currentPage = page;
      this.getDeposits();
    },
  },
};
</script>

<style scoped>
.settings .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #009b67 !important;
}
</style>
